<template>
  <x-card class="o2a-export-second">
    <heading level="3">
      Список товаров
    </heading>
    <template v-if="isPreloaderShown">
      <div class="o2a-export-second__importing">
        <x-loader />
        <heading
          v-if="isCountShown"
          level="4"
        >
          <template v-if="importedCount === totalCount">
            Финальная обработка
          </template>
          <template v-else>
            Загружено {{ importedCount }} из {{ totalCount }} товаров
          </template>
        </heading>
        <div
          v-if="isShopDetailsShown"
          class="o2a-export-second__importing-text"
        >
          Мы запустили импорт товаров из вашего магазина <strong>{{ fromShopName }}</strong>.
          Пожалуйста, дождитесь окончания импорта. После этого выберите товары, которые хотите загрузить на <strong>{{ toShopName }}</strong>
        </div>
      </div>
    </template>
    <template v-else>
      <alert-bar>
        Выберите товары для экспорта на AliExpress. Затем нажмите кнопку «Далее»
      </alert-bar>
      <ozon2-ali-export-filter
        class="o2a-export-second__filter"
        :category.sync="filters.category"
        :created-at-period.sync="filters.createdAtPeriod"
        :search.sync="filters.search"
        show-add-button
        show-global-controls
        @reset="resetFilters"
        @select-all-page="selectAllPage"
        @remove-all-page="removeAllPage"
        @select-all="selectAll"
        @remove-all="removeAll"
      >
        <template #filterTitle>
          Импортировано товаров: <strong>{{ totalCount }}</strong>
        </template>
        <template #controls>
          <checkbox
            v-model="filters.with_validation_errors"
            rounded
            class="order-list__checkbox mr-5"
          >
            Только ошибки: <strong>{{ errorCount }}</strong>
          </checkbox>
          <checkbox
            v-model="filters.hide_prev_imported_products"
            rounded
            class="order-list__checkbox"
          >
            Не показывать ранее добавленные товары
          </checkbox>
        </template>
      </ozon2-ali-export-filter>
      <template v-if="isListLoading">
        <x-loader />
      </template>
      <template v-else>
        <template v-if="productList.length">
          <grid-steps
            class="o2a-export-second__grid"
            :product-list="productList"
            :columns="columns"
            :product-errors="productErrors"
            @remove-row="removeRow"
            @select-row="selectRow"
          />
          <x-paginator
            :options="pagination"
            @input="setPage"
          />
        </template>
        <template v-else>
          <x-placeholder
            icon="table-empty"
            desc="Нет продуктов для добавления"
          />
          <template v-if="isImportFailed">
            <p class="o2a-export-second__error">
              Возникла ошибка импорта товаров из <strong>{{ fromShopName }}</strong>
            </p>
          </template>
        </template>
      </template>
    </template>
    <prop-edit-modal />
  </x-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import STATUS from '@/constants/status_api'
import { EXPORT_STATUS, IMPORT_STATUS, PRODUCT_STATUS } from '@/constants/status_mp-to-mp'

import Heading from '@/components/Common/Heading'
import AlertBar from '@/components/Interface/AlertBar'
import Ozon2AliExportFilter from '@/components/Ozon2Ali/Export/steps/Ozon2AliExportFilter'
import marketplaceName from '@/constants/marketplace-name'
import GridSteps from '@/components/Ozon2Ali/Export/GridSteps'
import Checkbox from '@/components/Interface/Checkbox.vue'
import PropEditModal from '@/components/Ozon2Ali/PropEditModal'

export default {
  name: 'Ozon2AliExportSecondStep',
  components: { GridSteps, Ozon2AliExportFilter, AlertBar, Heading, Checkbox, PropEditModal },
  data () {
    return {
      filters: {
        category: undefined,
        createdAtPeriod: {
          start: '',
          end: ''
        },
        search: '',
        with_validation_errors: false,
        hide_prev_imported_products: false
      },

      columns: [
        {
          name: 'ID на маркетплейсе',
          type: 'id',
          freezed: true,
          styles: {
            width: '190px'
          }
        },
        {
          name: 'Наименование',
          type: 'name',
          styles: {
            width: '190px'
          }
        },
        {
          name: 'Фото',
          hint: 'Мы загружаем первые 6 фотографий, потому что AliExpress не позволяет загрузить больше',
          type: 'image'
        },
        {
          name: 'Дата создания',
          hint: 'Дата создания карточки на маркетплейсе',
          type: 'createdAt'
        },
        {
          name: 'Категория',
          type: 'category'
        },
        {
          name: 'Причина ошибки',
          type: 'errors',
          styles: {
            'max-width': '300px'
          }
        },
        {
          name: 'Рекомендации',
          type: 'recommendations',
          styles: {
            'max-width': '300px'
          }
        },
        {
          name: 'Ссылка на маркетплейс',
          type: 'url',
          styles: {
            'max-width': '300px'
          }
        }
      ],

      stepParams: { statuses: [PRODUCT_STATUS.FOR_EXPORT, PRODUCT_STATUS.DRAFT] },

      taskDataIntervalId: null,

      pagination: {
        limit: 10,
        page: 1,
        offset: 0,
        total: 0
      },

      isOnlyErrorsShown: false
    }
  },
  watch: {
    filters: {
      handler () {
        this.resetPagination()
        this.fetchProductList()
      },
      deep: true
    }
  },
  computed: {
    ...mapState('ozon2ali/task', ['taskData', 'taskId', 'productList', 'productListStatus', 'taskLoadingStatus', 'fromShop', 'toShop']),
    ...mapGetters('ozon2ali/task', ['taskLoadingStatus', 'productListForExportIds']),

    isCountShown () {
      return this.taskLoadingStatus.importedAmount && this.taskLoadingStatus.productsAmount
    },

    isShopDetailsShown () {
      return this.fromShop?.name && this.toShop?.name
    },

    isPreloaderShown () {
      return this.isImporting && !this.isImportFailed
    },

    fromShopName () {
      return `${this.fromShop?.name} на ${marketplaceName[this.fromShop?.marketplace_code]}`
    },

    toShopName () {
      return `${this.toShop?.name} на ${marketplaceName[this.toShop?.marketplace_code]}`
    },

    importedCount () {
      return Number(this.taskLoadingStatus.importedAmount) || 0
    },

    errorCount () {
      return Number(this.taskLoadingStatus.errorCount) || 0
    },

    totalCount () {
      return Number(this.taskLoadingStatus.productsAmount) || 0
    },

    isImporting () {
      return !this.taskLoadingStatus.isProductsLoaded
    },

    isImportFailed () {
      return this.taskLoadingStatus.importStatus === IMPORT_STATUS.FAILURE
    },

    isListLoading () {
      return this.taskLoadingStatus.isProductsLoaded && this.productListStatus === STATUS.LOADING
    },

    productErrors () {
      const errorProducts = this.productList.filter(product => product.validation_error?.length)

      return errorProducts.reduce((out, product) => {
        out[`-${product.id}`] = ['']
        return out
      }, {})
    },

    paginationParams () {
      return {
        limit: this.pagination.limit,
        offset: this.pagination.offset
      }
    }
  },

  async created () {
    /* срабатывает если нажали кнопку "назад" на 3 шаге */
    if (this.taskId) {
      this.fetchProductList()
    } else {
      const taskId = this.$router.currentRoute.params.taskId
      if (taskId === 'new') {
        await this.createTask()
      } else {
        this.SET_TASK_ID(taskId)
      }

      await this.fetchTaskData()
      /* todo убрать после переделки на роуты */
      if (this.taskLoadingStatus.exportStatus !== EXPORT_STATUS.DRAFT) {
        this.setCurrentStep(4)
      }

      if (!this.taskLoadingStatus.isProductsLoaded) {
        this.createTaskPeriod()
      } else {
        this.fetchProductList()
      }
    }
  },

  beforeDestroy () {
    if (this.taskDataIntervalId) {
      clearInterval(this.taskDataIntervalId)
    }
  },

  methods: {
    ...mapActions('ozon2ali/task', [
      'setCurrentStep',
      'createTask',
      'fetchTaskData',
      'setItemsToExport',
      'removeExportItems',
      'fetchProductListAction',
      'selectAllProductsOnPage',
      'selectAllImportedProductsByFilter',
      'removeAllImportedProductsByFilter'
    ]),
    ...mapMutations('ozon2ali/task', ['SET_TASK_ID']),

    resetPagination () {
      this.pagination.page = 1
      this.pagination.offset = 0
    },

    createTaskPeriod () {
      const id = setInterval(async () => {
        await this.fetchTaskData()

        if (this.taskLoadingStatus.isProductsLoaded) {
          this.fetchProductList()
          clearInterval(id)
        }

        if (this.isImportFailed) {
          clearInterval(id)
        }
      }, 3000)

      this.taskDataIntervalId = id
    },

    setPage (page) {
      this.pagination = page
      this.fetchProductList()
    },

    async fetchProductList () {
      this.pagination.total = await this.fetchProductListAction({ ...this.paginationParams, ...this.getFilterParams(), ...this.stepParams })
    },

    getFilterParams () {
      const obj = {}

      if (this.filters.createdAtPeriod.start) {
        obj.created_at_from = this.filters.createdAtPeriod.start
      }

      if (this.filters.createdAtPeriod.end) {
        obj.created_at_to = this.filters.createdAtPeriod.end
      }

      if (this.filters.search) {
        obj.search = this.filters.search
      }

      if (this.filters.category && this.filters.category.id !== 'all') {
        obj.product_category_id = this.filters.category.id
      }

      if (this.filters.with_validation_errors) {
        obj.with_validation_errors = this.filters.with_validation_errors
      }

      if (this.filters.hide_prev_imported_products) {
        obj.hide_prev_imported_products = this.filters.hide_prev_imported_products
      }

      return obj
    },

    resetFilters () {
      this.filters = {
        category: undefined,
        createdAtPeriod: {
          start: '',
          end: ''
        },
        search: '',
        with_validation_errors: false,
        hide_prev_imported_products: false
      }
    },

    selectRow (row) {
      this.setItemsToExport([row.id])
    },

    removeRow (row) {
      this.removeExportItems({ productIds: [row.id] })
    },

    selectAllPage () {
      this.selectAllProductsOnPage()
    },

    removeAllPage () {
      this.removeExportItems({ productIds: this.productListForExportIds })
    },

    selectAll () {
      this.selectAllImportedProductsByFilter(this.getFilterParams())
    },

    removeAll () {
      this.removeAllImportedProductsByFilter({ filter: this.getFilterParams() })
    }
  }
}
</script>

<style lang="stylus">
.o2a-export-second
  margin-top 24px
  padding 24px
  min-height 340px
  display flex
  flex-direction column

  &__importing
    margin auto
    max-width 517px
    min-width 517px
    text-align center

    .x-loader
      margin-bottom 20px

    .heading
      margin-bottom 4px !important

    &-text
      color: $colors.gray-darker

  &__grid
    margin-bottom 20px

    .grid__cell_content_actions .grid__cell-in
      justify-content center

  .x-paginator
    margin-top: auto

  &__filter
    margin-top 20px
    margin-bottom 22px

  &__error {
    text-align center
    color red
  }
</style>
