<template>
  <x-card class="o2a-export-fourth">
    <heading level="3">
      Список товаров
    </heading>
    <x-alert>
      <div>
        — Редактируйте добавленные товары в <span class="font-weight-bold">старой версии страницы</span> редактирования товара. Иначе у вас поедет текст и таблица в описании товара. <br>
        — Большинство задач мы обрабатываем в течение 48 часов. Это зависит от количества карточек и очередности вашей задачи. <br>
        — Если в задаче много карточек товаров с разными модификациями, то обработка может занять немного больше времени. <br>
        — Некоторые карточки добавятся практически сразу, остальные могут уйти на дополнительную обработку. Это не значит что задача зависла или брошена. <br>
        — Товары добавятся в раздел “Снятые с продажи”. Перед выводом в продажу проверьте правильность переноса цен и остатков. <br>
      </div>
    </x-alert>
    <x-tabs
      v-model="selectedTab"
      :tabs="tabs"
      size="l"
      class="o2a-export-fourth__tabs"
      @change="onTabChange"
    />
    <ozon2-ali-export-filter
      class="o2a-export-fourth__filter"
      :category.sync="filters.category"
      :created-at-period.sync="filters.createdAtPeriod"
      :search.sync="filters.search"
      @reset="resetFilters"
    />
    <template v-if="isListLoading">
      <x-loader />
    </template>
    <template v-else>
      <grid-steps
        class="o2a-export-fourth__grid"
        :product-list="productList"
        :columns="columns"
        hide-actions
      />
      <x-paginator
        :options="pagination"
        @input="setPage"
      />
    </template>
  </x-card>
</template>

<script>
import dateFormat from '@/utils/date-formatter'
import { timeFormat } from '@/utils/formatters'
import Heading from '@/components/Common/Heading'
import Ozon2AliExportFilter from '@/components/Ozon2Ali/Export/steps/Ozon2AliExportFilter'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { PRODUCT_STATUS } from '@/constants/status_mp-to-mp'
import STATUS from '@/constants/status_api'
import GridSteps from '@/components/Ozon2Ali/Export/GridSteps'

export default {
  name: 'Ozon2AliExportFourthStep',
  components: { GridSteps, Heading, Ozon2AliExportFilter },
  data () {
    return {
      filters: {
        category: undefined,
        createdAtPeriod: {
          start: '',
          end: ''
        },
        search: ''
      },
      selectedTab: 0,
      stepParams: { statuses: PRODUCT_STATUS.DONE },
      pagination: {
        limit: 10,
        page: 1,
        offset: 0,
        total: 0
      },

      taskDataIntervalId: null
    }
  },
  watch: {
    filters: {
      handler () {
        this.resetPagination()
        this.fetchProductList()
      },
      deep: true
    }
  },
  computed: {
    ...mapState('ozon2ali/task', ['taskData', 'productList', 'productListStatus', 'countByType', 'taskId']),
    ...mapGetters('ozon2ali/task', [
      'taskLoadingStatus',
      'metricsPayload'
    ]),

    isListLoading () {
      return this.taskLoadingStatus.isProductsLoaded && this.productListStatus === STATUS.LOADING
    },

    columns () {
      let resultingColumns = [
        {
          name: 'ID на маркетплейсе',
          type: 'id',
          freezed: true,
          styles: {
            width: '190px'
          }
        },
        {
          name: 'Наименование',
          type: 'name',
          styles: {
            width: '190px'
          }
        },
        {
          name: 'Фото',
          type: 'image'
        },
        {
          name: 'Дата создания',
          type: 'createdAt'
        },
        {
          name: 'Категория',
          type: 'category'
        }
      ]
      switch (this.selectedTab) {
        case 0:
          resultingColumns = [
            {
              name: 'ID на маркетплейсе',
              type: 'id',
              freezed: true,
              styles: {
                width: '163px'
              }
            },
            {
              name: 'Наименование',
              type: 'name',
              styles: {
                width: '190px'
              }
            },
            {
              name: 'Фото',
              type: 'image'
            },
            {
              name: 'Дата создания',
              type: 'createdAt'
            },
            {
              name: 'Категория',
              type: 'category'
            },
            {
              name: 'Ссылка на маркетплейс',
              type: 'resultUrl'
            }
          ]
          break
        case 1:
          resultingColumns = [
            {
              name: 'ID на маркетплейсе',
              type: 'id',
              freezed: true,
              styles: {
                width: '163px'
              }
            },
            {
              name: 'Наименование',
              type: 'name',
              styles: {
                width: '190px'
              }
            },
            {
              name: 'Фото',
              type: 'image'
            },
            {
              name: 'Дата создания',
              type: 'createdAt'
            },
            {
              name: 'Категория',
              type: 'category'
            },
            {
              name: 'Причина ошибки',
              type: 'errors',
              styles: {
                'max-width': '300px'
              }
            },
            {
              name: 'Рекомендации',
              type: 'recommendations',
              styles: {
                'max-width': '300px'
              }
            }
          ]
          break
        case 2:
          resultingColumns = [
            {
              name: 'ID на маркетплейсе',
              type: 'id',
              freezed: true,
              styles: {
                width: '163px'
              }
            },
            {
              name: 'Наименование',
              type: 'name',
              styles: {
                width: '190px'
              }
            },
            {
              name: 'Фото',
              type: 'image'
            },
            {
              name: 'Дата создания',
              type: 'createdAt'
            },
            {
              name: 'Категория',
              type: 'category'
            },
            {
              name: 'Причина ошибки',
              type: 'errors',
              styles: {
                'max-width': '300px'
              }
            },
            {
              name: 'Рекомендации',
              type: 'recommendations',
              styles: {
                'max-width': '300px'
              }
            }
          ]
          break
        case 3:
          resultingColumns = [
            {
              name: 'ID на маркетплейсе',
              type: 'id',
              freezed: true,
              styles: {
                width: '163px'
              }
            },
            {
              name: 'Наименование',
              type: 'name',
              styles: {
                width: '190px'
              }
            },
            {
              name: 'Фото',
              type: 'image'
            },
            {
              name: 'Дата создания',
              type: 'createdAt'
            },
            {
              name: 'Категория',
              type: 'category'
            },
            {
              name: 'Причина ошибки',
              type: 'errors',
              styles: {
                'max-width': '300px'
              }
            },
            {
              name: 'Рекомендации',
              type: 'recommendations',
              styles: {
                'max-width': '300px'
              }
            },
            {
              name: 'Ссылка на маркетплейс',
              type: 'resultUrl'
            }
          ]
          break
        default:
          resultingColumns = [
            {
              name: 'ID на маркетплейсе',
              type: 'id',
              freezed: true,
              styles: {
                width: '163px'
              }
            },
            {
              name: 'Наименование',
              type: 'name',
              styles: {
                width: '190px'
              }
            },
            {
              name: 'Фото',
              type: 'image'
            },
            {
              name: 'Дата создания',
              type: 'createdAt'
            },
            {
              name: 'Категория',
              type: 'category'
            },
            {
              name: 'Причина ошибки',
              type: 'errors',
              styles: {
                'max-width': '300px'
              }
            },
            {
              name: 'Рекомендации',
              type: 'recommendations',
              styles: {
                'max-width': '300px'
              }
            }
          ]
          break
      }
      return resultingColumns
    },

    tabs () {
      const doneCount = this.countByType[PRODUCT_STATUS.DONE]
      const inProgressCount = this.countByType[PRODUCT_STATUS.IN_PROGRESS]
      const errorCount = this.countByType[PRODUCT_STATUS.REJECTED] + this.countByType[PRODUCT_STATUS.FAILURE]
      const allCount = doneCount + inProgressCount + errorCount

      return [
        {
          id: 'done',
          title: 'Загруженные',
          counter: doneCount,
          disabled: !doneCount,
          metrics: 'mp2mp_tasks_show_added'
        },
        {
          id: 'inprogress',
          title: 'В процессе',
          counter: inProgressCount,
          disabled: !inProgressCount,
          metrics: 'mp2mp_tasks_show_inprogress'
        },
        {
          id: 'error',
          title: 'Ошибочные',
          counter: errorCount,
          disabled: !errorCount,
          metrics: 'mp2mp_tasks_show_failed'
        },
        {
          id: 'all',
          title: 'Все',
          counter: allCount,
          disabled: !allCount,
          metrics: 'mp2mp_tasks_show_all'
        }
      ]
    },

    paginationParams () {
      return {
        limit: this.pagination.limit,
        offset: this.pagination.offset
      }
    }
  },

  async created () {
    this.hit()

    if (!this.taskId) {
      const taskId = this.$router.currentRoute.params.taskId
      this.SET_TASK_ID(taskId)
    }

    await this.fetchTaskData()
    this.fetchProductList()

    await this.getExportCountByType()
    if (this.countByType[PRODUCT_STATUS.IN_PROGRESS]) {
      this.createTaskPeriod()
    }
  },

  beforeDestroy () {
    if (this.taskDataIntervalId) {
      clearInterval(this.taskDataIntervalId)
    }
  },

  methods: {
    ...mapActions('ozon2ali/task', ['fetchTaskData', 'fetchProductListAction', 'getExportCountByType']),
    ...mapMutations('ozon2ali/task', ['SET_TASK_ID']),

    resetPagination () {
      this.pagination.page = 1
      this.pagination.offset = 0
    },

    setPage (page) {
      this.pagination = page
      this.fetchProductList()
    },

    createTaskPeriod () {
      const id = setInterval(async () => {
        await this.getExportCountByType()
        this.fetchProductList()
        this.fetchTaskData()

        if (!this.countByType[PRODUCT_STATUS.IN_PROGRESS]) {
          clearInterval(id)
        }
      }, 60000)

      this.taskDataIntervalId = id
    },

    async fetchProductList () {
      this.pagination.total = await this.fetchProductListAction({ ...this.paginationParams, ...this.getFilterParams(), ...this.getTabParams() })
    },

    getFilterParams () {
      const obj = {}

      if (this.filters.createdAtPeriod.start) {
        obj.created_at_from = this.filters.createdAtPeriod.start
      }

      if (this.filters.createdAtPeriod.end) {
        obj.created_at_to = this.filters.createdAtPeriod.end
      }

      if (this.filters.search) {
        obj.search = this.filters.search
      }

      if (this.filters.category && this.filters.category.id !== 'all') {
        obj.product_category_id = this.filters.category.id
      }

      return obj
    },

    formatDate (date) {
      return dateFormat(date)
    },

    formatTime (date) {
      return timeFormat(date)
    },

    resetFilters () {
      this.filters = {
        category: undefined,
        createdAtPeriod: {
          start: '',
          end: ''
        },
        search: ''
      }
    },

    onTabChange () {
      this.resetFilters()
      this.resetPagination()
      if (Object.keys(this.$route.query).length) {
        this.$router.push({ ...this.$route, query: {} })
      }
      this.fetchProductList()
      this.hit()
    },

    getTabParams () {
      const tabMap = [
        {
          statuses: PRODUCT_STATUS.DONE
        },
        {
          statuses: PRODUCT_STATUS.IN_PROGRESS
        },
        {
          statuses: [PRODUCT_STATUS.FAILURE, PRODUCT_STATUS.REJECTED]
        },
        {
          statuses: [PRODUCT_STATUS.FAILURE, PRODUCT_STATUS.REJECTED, PRODUCT_STATUS.IN_PROGRESS, PRODUCT_STATUS.DONE]
        }
      ]

      return tabMap[this.selectedTab]
    },

    hit () {
      const eventName = this.tabs[this.selectedTab].metrics
      this.$metrics.hit(eventName, this.metricsPayload)
    }
  }
}
</script>

<style lang="stylus">
.o2a-export-fourth
  margin-top 24px
  padding 24px
  min-height 318px

  &__filter
    margin-top 20px

  &__tabs
    margin-top 20px

  &__grid
    margin-bottom 20px

  .x-paginator
    margin-top: auto

  &__filter
    margin-top 20px
    margin-bottom 22px
</style>
