<template>
  <grid
    auto
    :actions="actions"
    :cells="productList"
    :columns="columns"
    :errors="productErrors"
  >
    <template #id="{row}">
      <div class="grid-steps__id">
        <div class="external-title">
          <div class="external-title-id">
            <div>{{ getExternalId(row) }}</div>
            <div v-if="row.origin_product_marketplace_url">
              <x-link
                v-hint="{
                  popperClass: 'grid-steps-tooltip',
                  placement: 'auto',
                  content: 'Ссылка на страницу товара на маркетплейсе',
                  triggers: ['hover'],
                  delay: 100
                }"
                primary
                :href="row.origin_product_marketplace_url"
              >
                <x-icon
                  name="link"
                  size="s"
                  class="modal-body__icon"
                />
              </x-link>
            </div>
          </div>
          <div
            v-if="hasOffer(row)"
          >
            <span
              v-if="hasFieldError(row, 'vendor_code')"
              class="grid-steps__vendor-code grid-steps__vendor-code--editable"
              @click="onPropEdit(row, 'vendor_code')"
            >
              <span class="grid-steps__vendor-code-prepend">Артикул: </span><span class="grid-steps__vendor-code-editable-text">{{ row.product_data.offers[0].vendor_code }}&nbsp;</span>
              <x-icon
                class="grid-steps__vendor-code-icon"
                name="edit"
                size="s"
              />
            </span>
            <span
              v-else
              class="grid-steps__vendor-code grid-steps__vendor-code--not-editable"
            >
              Артикул: {{ row.product_data.offers[0].vendor_code }}
            </span>
          </div>
          <span v-if="hasBarcode(row)">Штрихкод: {{ row.product_data.offers[0].barcode }}</span>
        </div>
      </div>
    </template>
    <template #name="{row}">
      <div>
        <span
          v-if="hasFieldError(row, 'name')"
          class="grid-steps__name grid-steps__name--editable"
          @click="onPropEdit(row, 'name')"
        >
          <span class="grid-steps__name-editable-text">{{ getProductName(row) }}</span>
          <x-icon
            class="grid-steps__name-icon"
            name="edit"
            size="s"
          />
        </span>
        <span
          v-else
          class="grid-steps__name grid-steps__name--not-editable"
        >
          {{ getProductName(row) }}
        </span>
      </div>
    </template>
    <template #image="{row}">
      <div
        class="grid-steps__image"
      >
        <template v-if="hasImages(row)">
          <div>
            <div
              v-for="(photo, pId) in row.product_data.images.slice(0, 6)"
              :key="pId"
            >
              <img
                :src="photo.url"
                alt=""
              >
            </div>
          </div>
        </template>
      </div>
    </template>
    <template #category="{row}">
      {{ getCategoryName(row) }}
    </template>
    <template #errors="{row}">
      <template
        v-if="hasError(row)"
      >
        <div class="grid-steps__item-error">
          <div>
            <p
              v-for="(error, i) in row.validation_error"
              :key="i"
              class="grid-steps__item-error-text"
            >
              <i-tooltip
                v-if="error.details"
                class="mr-1"
                :content="error.details"
              />

              {{ error.text }}
            </p>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="grid-steps__item-error">
          <div>
            <p
              class="grid-steps__item-error-notext"
            >
              Нет ошибок
            </p>
          </div>
        </div>
      </template>
    </template>
    <template #recommendations="{row}">
      <template v-if="hasError(row)">
        <div class="grid-steps__item-error">
          <div>
            <p
              v-for="(error, i) in row.validation_error"
              :key="i"
              class="grid-steps__item-error-text"
            >
              {{ error.recommendation }}
            </p>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="grid-steps__item-error">
          <div>
            <p
              class="grid-steps__item-error-notext"
            >
              Нет рекомендаций
            </p>
          </div>
        </div>
      </template>
    </template>
    <template #url="{row}">
      <template v-if="row.prev_imported_product_marketplace_url">
        <x-link
          v-hint="{
            popperClass: 'grid-steps-tooltip',
            placement: 'auto',
            content: row.prev_imported_product_marketplace_url,
            triggers: ['hover'],
            delay: 100
          }"
          primary
          :href="row.prev_imported_product_marketplace_url"
        >
          Ссылка на сайт
        </x-link>
      </template>
      <template v-else>
        <div class="grid-steps__item-error">
          <div>
            <p class="grid-steps__item-error-notext">
              Товар ранее не добавлялся
            </p>
          </div>
        </div>
      </template>
    </template>
    <template #actions="{row}">
      <template v-if="removeOnly">
        <x-btn
          outline
          prepend="delete-2"
          metrics="mp2mp_export_delete"
          :metrics-payload="metricsPayload"
          @click="$emit('remove-row', row)"
        >
          Удалить
        </x-btn>
      </template>
      <template v-else>
        <x-btn
          v-if="isRowSelected(row)"
          :disabled="isRowDisabled(row)"
          prepend="check"
          metrics="mp2mp_export_delete"
          :metrics-payload="metricsPayload"
          @click="$emit('remove-row', row)"
        >
          Добавлено
        </x-btn>
        <x-btn
          v-else
          :disabled="isRowDisabled(row)"
          prepend="plus"
          outline
          metrics="mp2mp_export_add"
          :metrics-payload="metricsPayload"
          @click="$emit('select-row', row)"
        >
          Добавить
        </x-btn>
      </template>
    </template>
    <template #createdAt="{ row }">
      {{ formatDate(row.created_at) }} <br>
      <span class="grid-steps__time">{{ formatTime(row.created_at) }}</span>
    </template>
    <template #resultUrl="{ row }">
      <template v-if="row.result_product_marketplace_url">
        <x-link
          v-hint="{
            popperClass: 'grid-steps-tooltip',
            placement: 'auto',
            content: row.result_product_marketplace_url,
            triggers: ['hover'],
            delay: 100
          }"
          primary
          :href="row.result_product_marketplace_url"
        >
          Ссылка на сайт
        </x-link>
      </template>
      <template v-else>
        <div class="grid-steps__item-error">
          <div>
            <p class="grid-steps__item-error-notext">
              —
            </p>
          </div>
        </div>
      </template>
    </template>
  </grid>
</template>

<script>
import { PRODUCT_STATUS } from '@/constants/status_mp-to-mp'
import dateFormat from '@/utils/date-formatter'
import { timeFormat } from '@/utils/formatters'

import Grid from '@/components/Interface/Grid'
import { mapGetters } from 'vuex'
import ITooltip from '@/components/Interface/ITooltip'

export default {
  name: 'GridSteps',

  components: {
    ITooltip,
    Grid
  },

  props: {
    productList: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array,
      default: () => []
    },
    productErrors: {
      type: Object,
      default: () => ({})
    },

    removeOnly: Boolean,

    hideActions: Boolean
  },

  computed: {
    ...mapGetters('ozon2ali/task', [
      'metricsPayload'
    ]),

    actions () {
      return this.hideActions ? [] : ['stub']
    }
  },

  methods: {
    onPropEdit (row, propName) {
      this.$modal.show('prop-edit-modal', { productData: row, propName })
    },

    hasFieldError (row, field) {
      return Boolean(row.validation_error?.find(error => error.field === field))
    },

    hasError (row) {
      return row.validation_error?.length
    },

    getCategoryName (row) {
      return row.product_data?.category?.name || ''
    },

    getExternalId (row) {
      return row.product_data?.external_id
    },

    getProductName (row) {
      return row.product_data?.name || ''
    },

    hasOffer (row) {
      return row.product_data?.offers?.length
    },

    hasBarcode (row) {
      return row.product_data?.offers?.length && row.product_data.offers[0].barcode
    },

    hasImages (row) {
      return row.product_data?.images?.length
    },

    formatDate (date) {
      return dateFormat(date, 'DD.MM.YYYY', { utc: '' })
    },

    formatTime (date) {
      return timeFormat(date, 'HH:mm:ss', { utc: '' })
    },

    isRowSelected (row) {
      return row.status === PRODUCT_STATUS.FOR_EXPORT
    },

    isRowDisabled (row) {
      return (row.status !== PRODUCT_STATUS.FOR_EXPORT && row.status !== PRODUCT_STATUS.DRAFT) ||
        /* блокируем элементы с ошибкой */
        !!row.validation_error?.length
    }
  }
}
</script>

<style lang="stylus">
.grid-steps-tooltip
  .v-popper__inner
    background: #2C2C37
    font-weight 500
    font-size 14px
    line-height 16px
  .v-popper__arrow-inner
    border-color: #2C2C37

.grid-steps
  &__name
    display flex
    font-weight 600
    font-size 14px
    line-height 18px

    &--not-editable
      display: -webkit-box
      -webkit-line-clamp 3
      -webkit-box-orient vertical
      overflow hidden

    &--editable
      cursor pointer
      color #3C3C47

      &:hover {
        color #5b40ff
      }

  &__name-editable-text
    color inherit
    margin-left 4px
    display: -webkit-box
    -webkit-line-clamp 3
    -webkit-box-orient vertical
    overflow hidden
    transition color .2s
    text-decoration underline
    text-decoration-style dashed
    text-underline-offset 2px

  &__name-icon {
    padding-left 4px
    color #5b40ff
  }

  &__vendor-code
    display flex
    align-items center
    margin-bottom 4px

    &--not-editable
      display block
      white-space nowrap
      text-overflow ellipsis
      overflow hidden

    &--editable
      cursor pointer
      color #3C3C47

      &:hover {
        color #5b40ff
      }

  &__vendor-code-icon {
    padding-left 4px
    color #5b40ff
  }

  &__vendor-code-prepend {
    color #a3a3ac
  }

  &__vendor-code-editable-text
    color inherit
    margin-left 4px
    display inline-block
    text-overflow ellipsis
    overflow hidden
    white-space nowrap
    text-decoration underline
    text-decoration-style dashed
    text-underline-offset 2px
    transition color .2s

  &__item-error-notext {
    margin-bottom 4px
    width 100%
    div {
      width 100%
      p {
        width 100%
        text-align center
      }
    }
  }

  &__product-link
    display block
    white-space nowrap
    text-overflow ellipsis
    overflow hidden

  &__image
    display flex
    flex-direction row
    height 56px
    width 100%
    overflow-x hidden
    div
      height 100%
      display flex
      flex-direction row
      flex-wrap nowrap
      div
        height 100%
        width 56px
        padding 4px
        background-color #FFFFFF
        border 1px solid rgba(44, 44, 55, 0.1)
        box-sizing border-box
        border-radius 4px
        justify-content center
        &:not(first-child)
          margin-left 4px
        img
          max-width 100%
          max-height 100%
          object-fit: contain

  &__id
    font-weight 400
    font-size 12px
    line-height 16px
    color #A3A3AC
    overflow hidden

  &__error {
    text-align center
    color red
  }

  &__item-error {
    display flex
    width 100%
  }

  &__item-error-text {
    display flex
    align-items center
    margin-bottom 4px
  }

  &__item-error-icon {
    width 16px
    height 16px
    color #CACAD3
    margin-right 4px
  }

  &__time
    color: $colors.gray-darker
    margin-top 4px
    display inline-block

.external-title
  .external-title-id
    width 100%
    display flex
    flex-direction row
    align-items center
    justify-content space-between
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: $colors.gray-darker
    svg
      height 15px !important
      width 16px !important

.grid__action_freezed
  border-top 1px solid #d7d6d6 !important

.grid__row_heading .grid__heading
  border-top 1px solid #d7d6d6 !important

.photo-expand-enter-active
  transition all .3s ease

.photo-expand-leave-active
  transition all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0)

.photo-expand-enter, .photo-expand-leave-to
  transform translateX(10px)
  opacity 0

.grid__heading-in
  padding-top 10px

.edit-button
  margin-right 4px
  align-self flex-start
</style>
