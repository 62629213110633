<template>
  <modal
    name="prop-edit-modal"
    :max-width="540"
    :min-width="280"
    width="90%"
    height="auto"
    adaptive
    @before-open="onBeforeOpen"
  >
    <form
      class="prop-edit-modal"
      @submit.prevent="onSubmit"
    >
      <h3 class="prop-edit-modal__header">
        Редактирование поля "{{ fieldName }}"
      </h3>

      <textarea
        v-model="propValue"
        class="prop-edit-modal__textarea"
        :class="textAreaClasses"
        :minlength="3"
        :maxlength="maxLength"
      />

      <div class="counter-wrapper">
        <span class="prop-edit-modal__counter">
          {{ currentLength }} / {{ maxLength }}
        </span>
      </div>

      <div class="form-controls">
        <x-btn
          type="submit"
          :disabled="isLoading"
        >
          Применить
        </x-btn>
        <x-btn
          outline
          type="button"
          @click="onCancel"
        >
          Отменить
        </x-btn>
      </div>
    </form>
  </modal>
</template>

<script>
import { changeProductProp } from '@/api/default/modules/ozon2ali'
import { mapMutations } from 'vuex'

export default {
  name: 'PropEditModal',
  data: () => ({
    isLoading: false,
    propName: '',
    propValue: '',
    productId: null,

    propSettings: {
      name: {
        title: 'Наименование',
        maxLength: 128
      },

      vendor_code: {
        title: 'Артикул',
        maxLength: 50
      }
    }
  }),

  computed: {
    textAreaClasses () {
      return {
        'prop-edit-modal__textarea--error': this.currentLength > this.maxLength
      }
    },

    fieldName () {
      return this.propSettings[this.propName]?.title
    },

    maxLength () {
      return this.propSettings[this.propName]?.maxLength
    },

    currentLength () {
      return this.propValue?.length
    },

    isFormValid () {
      return this.currentLength <= this.maxLength
    }
  },

  methods: {
    ...mapMutations('ozon2ali/task', ['REPLACE_PRODUCT']),

    async onSubmit () {
      if (!this.isFormValid) {
        this.$toast.error('Недопустимое значение')
        return
      }
      try {
        this.isLoading = true
        let requestData = {}

        if (this.propName === 'name') {
          requestData = {
            product_data: {
              name: this.propValue
            }
          }
        }

        if (this.propName === 'vendor_code') {
          requestData = {
            product_data: {
              offers: [{
                'vendor_code': this.propValue
              }]
            }
          }
        }

        const { data } = await changeProductProp(this.$route.params.taskId, this.productId, requestData)

        this.REPLACE_PRODUCT(data.data)
        this.isLoading = false
        this.$modal.hide('prop-edit-modal')
      } catch (e) {
        this.$toast.error('Возникла ошибка. Попробуйте снова')
        this.isLoading = false
      }
    },

    onCancel () {
      this.$modal.hide('prop-edit-modal')
    },

    onBeforeOpen (e) {
      this.propName = e.params.propName
      this.productId = e.params.productData.id
      if (e.params.propName === 'name') {
        this.propValue = e.params.productData?.product_data?.name
      }
      if (e.params.propName === 'vendor_code') {
        this.propValue = e.params.productData?.product_data?.offers[0]?.vendor_code
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.prop-edit-modal
  display flex
  flex-direction column
  padding 32px

  &__header
    font-size 20px
    font-weight 700
    margin-bottom 24px

  &__textarea
    font-size 16px
    line-height 20px
    padding 8px 12px
    height 110px
    margin-bottom 8px
    border-radius 8px

    &--error
      outline 2px solid red

.form-controls
  margin-top auto

.counter-wrapper
  text-align right
  margin-bottom 24px

</style>
