<template>
  <div class="mp2mp">
    <div class="mp2mp__item">
      <div class="mp2mp__icon">
        <x-icon
          :name="getMarketplaceIcon(from.marketplace_code)"
          size="s"
        />
      </div>
      <template v-if="!withoutName">
        <div class="mp2mp__title">
          {{ from.name }}
        </div>
      </template>
    </div>
    <div class="mp2mp__arrow">
      <svg-icon name="arrow-right-small" />
    </div>
    <div class="mp2mp__item">
      <div class="mp2mp__icon">
        <x-icon
          :name="getMarketplaceIcon(to.marketplace_code)"
          size="s"
        />
      </div>
      <template v-if="!withoutName">
        <div class="mp2mp__title">
          {{ to.name }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { getMarketplaceIcon } from '@/utils/getMarketplaceIcon'

export default {
  name: 'Mp2Mp',
  props: {
    from: {
      type: Object,
      required: true
    },
    to: {
      type: Object,
      required: true
    },
    withoutName: Boolean
  },
  methods: {
    getMarketplaceIcon
  }
}
</script>

<style lang="stylus">
.mp2mp
  display flex
  align-items center
  gap 8px

  &__item
    display flex
    align-items center
    gap 4px

  &__title
    font-weight 600

  &__arrow
    min-width: 20px
    max-width 20px
    height: 20px

    svg
      min-width 20px
      max-width 20px
      height 16px
</style>
