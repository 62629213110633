<template>
  <x-card class="o2a-export-select">
    <heading level="3">
      Направление экспорта товаров
    </heading>
    <alert-bar>
      Выберите направление экспорта товаров.<br>
      Обратите внимание, что оба магазина должны быть <strong>Активны</strong>, если вы не можете найти свой магазин, проверьте его статус в
      <x-link
        :to="{ name: ROUTES.MARKETPLACES.name }"
        target="_blank"
      >
        Мои магазины
      </x-link>
    </alert-bar>
    <div class="o2a-export-select__container">
      <x-form-item
        name="Из какого магазина берём товары"
        label="Из какого магазина берём товары"
        class="o2a-export-select__select"
      >
        <x-select
          :value="fromShop"
          :options="fromShops"
          track-by="pk"
          label="name"
          @input="setFromShop"
        />
      </x-form-item>
      <div class="o2a-export-select__arrow">
        <svg-icon name="arrow-right-small" />
      </div>
      <x-form-item
        name="В какой магазин добавляем товары"
        label="В какой магазин добавляем товары"
        class="o2a-export-select__select"
      >
        <x-select
          :value="toShop"
          :options="toShops"
          track-by="pk"
          label="name"
          @input="setToShop"
        />
      </x-form-item>
    </div>
  </x-card>
</template>

<script>
import Heading from '@/components/Common/Heading'
import AlertBar from '@/components/Interface/AlertBar'
import { mapActions, mapGetters, mapState } from 'vuex'
import { getMarketplaceIcon } from '@/utils/getMarketplaceIcon'
import ROUTES from '@/router/ROUTES'

export default {
  name: 'Ozon2AliExportSelectShops',
  components: { AlertBar, Heading },

  data: () => ({
    ROUTES
  }),

  computed: {
    ...mapGetters('ozon2ali', ['aliShops', 'wbShops', 'ozonShops']),
    ...mapState('ozon2ali/task', ['fromShop', 'toShop']),

    fromShops () {
      return [...this.wbShops, ...this.ozonShops].map(item => {
        return {
          ...item,
          $icon: getMarketplaceIcon(item.marketplace_code)
        }
      }).filter(item => this.isShopValid(item))
    },

    toShops () {
      return this.aliShops.map(item => {
        return {
          ...item,
          $icon: getMarketplaceIcon(item.marketplace_code)
        }
      }).filter(item => this.isShopValid(item))
    }
  },
  created () {
    this.fetchShops()
  },
  methods: {
    ...mapActions('ozon2ali', ['fetchShops']),
    ...mapActions('ozon2ali/task', ['setFromShop', 'setToShop']),

    isShopValid (shop) {
      return shop.has_valid_token && shop.is_active
    }
  }

}
</script>

<style lang="stylus">
.o2a-export-select
  margin-top 24px
  padding 24px
  min-height 318px

  &__container
    display flex
    align-items center
    margin-top 20px
    gap 40px

  &__select
    min-width 352px
    max-width 352px

  &__arrow
    min-width 24px
    max-width 24px
    height 24px
    margin-top 18px

    svg
      min-width 24px
      max-width 24px
      height 24px
</style>
