<template>
  <div :class="alertBarClasses">
    <div class="alert-bar__icon">
      <svg-icon :name="iconName" />
    </div>
    <div class="alert-bar__text">
      <slot />
    </div>
  </div>
</template>

<script>

const TYPES = [
  {
    name: 'info',
    iconName: 'info-fill'
  },
  {
    name: 'success',
    iconName: 'success-fill'
  }
]

export default {
  name: 'AlertBar',
  props: {
    type: {
      type: String,
      default: 'info',
      validator: v => TYPES.map(item => item.name).includes(v)
    }
  },
  computed: {
    iconName () {
      return TYPES.find(item => item.name === this.type)?.iconName
    },

    alertBarClasses () {
      return [
        'alert-bar',
        'alert-bar--' + this.type
      ]
    }
  }
}
</script>

<style lang="stylus">
.alert-bar
  padding 8px
  width 100%
  display flex

  &__icon
    min-width 16px
    max-width 16px
    height 16px
    margin-right 8px

    svg
      min-width 16px
      max-width 16px
      height 16px

  &--info
    background-color: $colors.primary-lightest-2
    border 1px solid $colors.primary-light
    border-radius 4px

    .alert-bar__icon
      color: $colors.primary
</style>
