<template>
  <x-dialog
    class="text"
    size="m"
    :submit-text="submitText"
    :submit-disabled="isCheckingBalance || isPaymentInProcess || priceDetailsLoading"
    @submit="onSubmit"
    @cancel="onDialogCancel"
  >
    <template #title>
      Настройки экспорта товаров
    </template>
    <x-dotline-spacer class="mb-3">
      <template #label>
        <div class="d-flex">
          <div class="text">
            Стандартная загрузка
          </div>
          <x-icon-tooltip class="ml-2">
            Атрибуты товаров мы переносим, создавая произвольные поля в карточке.
            Стандартные поля с атрибутами категории останутся пустыми.
          </x-icon-tooltip>
        </div>
      </template>
      {{ pricePerUnit }} руб/карточка
    </x-dotline-spacer>
    <div
      v-for="item in standardCheckboxes"
      :key="item"
      class="mb-2"
    >
      <x-checkbox
        :model-value="true"
        disabled
      >
        {{ item }}
      </x-checkbox>
    </div>
    <div class="row mb-3">
      <div class="col-xl-6 d-flex align-center">
        <x-checkbox
          :model-value="true"
          disabled
        >
          Шаблон доставки для новых товаров
        </x-checkbox>
      </div>
      <div class="col-xl-6">
        <x-select
          :allow-empty="false"
          :value="{ id:1, name: 'Почта России' }"
          track-by="id"
          label="name"
          size="xs"
          disabled
        />
      </div>
    </div>
    <x-alert class="mb-5">
      <span class="font-weight-semibold mr-1">Не переносим:</span> Бренд
      <x-icon-tooltip class="ml-1">
        Обратите внимание, бренд не будет перенесен. Это ограничение AliExpress.
        Маркетплейс запрещает заполнять поле "бренд" для товаров, добавленных через API.
      </x-icon-tooltip>
    </x-alert>
    <div class="mb-5">
      <div class="headline-5 mb-3">
        Дополнительно оплачивается
      </div>
      <x-dotline-spacer class="mb-2">
        <template #label>
          <x-checkbox disabled>
            Видео (скоро)
          </x-checkbox>
        </template>
        <div class="gray-darker--text">
          5 руб/карточка
        </div>
      </x-dotline-spacer>
      <x-dotline-spacer class="mb-2">
        <template #label>
          <x-checkbox disabled>
            Rich-контент (скоро)
          </x-checkbox>
        </template>
        <div class="gray-darker--text">
          10 руб/карточка
        </div>
      </x-dotline-spacer>
      <x-dotline-spacer class="mb-2">
        <template #label>
          <x-checkbox disabled>
            Не менять шаблон доставки для добавленных ранее товаров (скоро)
          </x-checkbox>
        </template>
        <div class="gray-darker--text">
          Бесплатно
        </div>
      </x-dotline-spacer>
      <x-dotline-spacer>
        <template #label>
          <x-checkbox v-model="exportParams.exportKeywords">
            Генерация ключевых слов
          </x-checkbox>
          <x-icon-tooltip class="ml-1">
            Правильные ключевые слова в заголовке карточки помогут показать ее большему числу покупателей.
            Наша система подберет наиболее подходящие ключевики в базе данных AliExpress.
            После завершения задачи на перенос карточек мы добавим ключи в каждую карточку в задаче.
            Это займет до 48 часов. Ключевые слова отобразятся только в английском названии карточки,
            но будут работать и для поиска на русском языке.
            <br>
            <br>
            Пример SEO-оптимизации заголовка:
            <br>
            <br>
            <div class="font-weight-semibold">
              Было:
            </div>
            *Bowl alternative, Charlotte, 3 L
            <br>
            Balls New Year Monte Christmas, winter forest, 7 cm, 4 PCs.*
            <br>
            <br>
            <div class="font-weight-semibold">
              Стало:
            </div>
            *Bowl alternative, Charlotte, 3 L Kitchen supplies Bowls Tableware Dining Bar Home Garden
            <br>
            Balls New Year Monte Christmas, winter forest, 7 cm, 4 PCs. Decoration Christmas Ball Ornaments Festive Party Supplies Home Garden*
          </x-icon-tooltip>
        </template>
        10 руб/карточка
      </x-dotline-spacer>
    </div>
    <x-divider class="mb-5" />
    <x-dotline-spacer class="headline-5">
      <template #label>
        Итого к оплате:
      </template>
      {{ totalPriceText }} руб
    </x-dotline-spacer>
    <div class="caption gray-darker--text mb-2 text-right">
      (на балансе {{ balanceText }} руб)
    </div>
    <x-dotline-spacer class="caption gray-darker--text">
      <template #label>
        Стандартная загрузка
      </template>
      за {{ cardsCountFormatted }}
    </x-dotline-spacer>
  </x-dialog>
</template>

<script>
import countFormatter from '@/utils/count-formatter'
import { initCardPayment } from '@/api/default/modules/payment'

import { createNamespacedHelpers, mapActions, mapGetters, mapState } from 'vuex'
import STATUS from '@/constants/status_api'

const { mapActions: financesActions, mapState: financesState } = createNamespacedHelpers('settings/finances')

const cardsFormatter = countFormatter({ one: 'карточку', two: 'карточки', few: 'карточек' })

export default {
  name: 'Ozon2AliExportPayModal',
  props: {
    onSuccess: {
      type: Function,
      required: true
    },
    onCancel: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      standardCheckboxes: [
        'Описание',
        'Характеристики (Атрибуты)',
        'Фотографии',
        'Габариты доставки',
        'Цены, цены со скидкой',
        'Остатки'
      ],
      isCheckingBalance: false,
      checkBalanceIntervalId: null,
      priceDetailsLoading: true,
      exportParams: {
        exportKeywords: false
      }
    }
  },
  watch: {
    exportParams: {
      async handler (exportParams) {
        this.priceDetailsLoading = true
        try {
          await this.fetchPriceDetails({
            export_keywords: exportParams.exportKeywords
          })
        } finally {
          this.priceDetailsLoading = false
        }
      },
      deep: true,
      immediate: true
    }
  },

  computed: {
    ...financesState(['balanceInfo']),
    ...mapState('ozon2ali/task', [
      'priceDetails',
      'exportActionStatus'
    ]),
    ...mapGetters('ozon2ali/task', [
      'payMetricsPayload'
    ]),

    pricePerUnit () {
      return this.priceDetails?.tariffs?.default?.price_per_unit || '...'
    },

    submitText () {
      return this.isCheckingBalance ? 'Ожидаем оплаты...' : 'Оплатить'
    },

    cardsCount () {
      return typeof this.priceDetails?.product_count === 'number'
        ? Number(this.priceDetails?.product_count).toLocaleString() : '...'
    },

    balanceText () {
      return typeof this.balanceInfo?.available_balance === 'number'
        ? Number(this.balanceInfo?.available_balance).toLocaleString() : '...'
    },

    totalPrice () {
      return this.priceDetails?.export_list_price
    },

    totalPriceText () {
      return this.totalPrice ?? '...'
    },

    cardsCountFormatted () {
      return `${this.cardsCount} ${cardsFormatter(this.cardsCount)}`
    },

    isMoneyEnough () {
      return this.balanceInfo?.available_balance >= this.totalPrice
    },

    needToPay () {
      return this.totalPrice - this.balanceInfo?.available_balance
    },

    isPaymentInProcess () {
      return this.exportActionStatus === STATUS.LOADING
    }
  },

  created () {
    this.getUserBalance()
    this.fetchPriceDetails()
  },

  beforeDestroy () {
    if (this.checkBalanceIntervalId) {
      clearInterval(this.checkBalanceIntervalId)
    }
  },

  methods: {
    ...financesActions(['getUserBalance']),
    ...mapActions('ozon2ali/task', ['fetchPriceDetails', 'startExportAction']),

    async onSubmit () {
      this.$metrics.hit('mp2mp_pay', this.payMetricsPayload)

      if (this.isMoneyEnough) {
        await this.startExportAction({
          export_keywords: this.exportParams.exportKeywords
        })
        if (this.exportActionStatus === STATUS.SUCCESS) {
          this.closeModal()
        }
      } else {
        await this.initCardPayment()
        this.startCheckingBalance()
      }
    },

    startCheckingBalance () {
      this.isCheckingBalance = true

      const id = setInterval(async () => {
        await this.getUserBalance()

        if (this.isMoneyEnough) {
          await this.startExportAction()
          clearInterval(id)
          this.isCheckingBalance = false
          this.closeModal()
        }
      }, 15000)
      this.checkBalanceIntervalId = id
    },

    closeModal () {
      this.$dialog.hide()
      this.onSuccess()
    },

    async initCardPayment () {
      try {
        const { data } = await initCardPayment({
          amount: this.needToPay
        })

        if (data.data) {
          window.open(
            data.data.payment_url,
            '_blank'
          )
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err)
      }
    },

    onDialogCancel () {
      this.$dialog.hide()
      this.onCancel()
    }
  }

}
</script>
