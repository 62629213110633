<template>
  <x-card class="o2a-export-header">
    <div class="o2a-export-header__left">
      <heading
        level="2"
        class="o2a-export-header__heading"
      >
        Задача №
        <template v-if="taskData && taskData.id">
          {{ taskData.id }}
        </template>
      </heading>
      <ozon2-ali-export-step
        :current-step="currentStep"
        :step-count="totalSteps"
      />
      <div class="o2a-export-header__left-bottom">
        <div class="o2a-export-header__item">
          <div class="o2a-export-header__item-label">
            Направление
          </div>
          <div class="o2a-export-header__item-value">
            <template v-if="taskData && taskData.shop_from && taskData.shop">
              <Mp2Mp
                :from="taskData.shop_from"
                :to="taskData.shop"
              />
            </template>
            <template v-else>
              -
            </template>
          </div>
        </div>
        <div class="o2a-export-header__item">
          <div class="o2a-export-header__item-label">
            Добавлено на экспорт:
          </div>
          <div class="o2a-export-header__item-value">
            <template v-if="taskData && taskData.exported_count">
              {{ taskData.exported_count }}
            </template>
            <template v-else>
              -
            </template>
          </div>
        </div>
        <div class="o2a-export-header__item">
          <div class="o2a-export-header__item-label">
            Статус:
          </div>
          <div class="o2a-export-header__item-value">
            <task-status
              v-if="taskData.id"
              :task="taskData"
            />
            <span v-else>-</span>
          </div>
        </div>
      </div>
    </div>
    <div class="o2a-export-header__right">
      <x-btn
        v-if="currentStep === 1"
        :disabled="isGoToSecondStepDisabled"
        metrics="mp2mp_newexport_next"
        :metrics-payload="metricsPayload"
        @click="goToSecondStep"
      >
        Далее
      </x-btn>
      <x-btn
        v-if="currentStep === 2"
        metrics="mp2mp_export_next"
        :metrics-payload="metricsPayload"
        :disabled="isCheckingCount"
        @click="goToThirdStep"
      >
        Далее
      </x-btn>
      <x-btn
        v-if="currentStep === 3"
        metrics="mp2mp_export_pay"
        :metrics-payload="payMetricsPayload"
        :disabled="isCheckingCount"
        @click="goToFourthStep"
      >
        Оплатить и Экспортировать
      </x-btn>
      <x-btn
        v-else-if="currentStep === 4"
        @click="goToTaskList"
      >
        Перейти к списку задач
      </x-btn>
      <template v-if="currentStep > 1">
        <x-btn
          color="gray"
          :disabled="isBackBtnDisabled"
          @click="goToPrevStep"
        >
          Назад
        </x-btn>
      </template>
      <template v-if="taskData">
        <div class="o2a-export-header__date">
          Дата запуска: <span class="o2a-export-header__date--date">{{ formatDate(taskData.created_at) }}</span> <span
            class="o2a-export-header__date--time"
          >{{ formatTime(taskData.created_at) }}</span>
        </div>
      </template>
    </div>
  </x-card>
</template>

<script>
import Ozon2AliExportStep from '@/components/Ozon2Ali/Export/Ozon2AliExportStep'
import Heading from '@/components/Common/Heading'
import { mapActions, mapState, mapGetters } from 'vuex'

import dateFormat from '@/utils/date-formatter'
import { timeFormat } from '@/utils/formatters'
import Mp2Mp from '@/components/Ozon2Ali/Mp2Mp'
import Ozon2AliExportPayModal from '@/components/Ozon2Ali/Export/Ozon2AliExportPayModal'
import { EXPORT_STATUS, PRODUCT_STATUS } from '@/constants/status_mp-to-mp'
import TaskStatus from '@/components/Ozon2Ali/TaskStatus'
import toastNotification from '@/utils/toast-notification'

export default {
  name: 'Ozon2AliExportHeader',
  components: { TaskStatus, Mp2Mp, Heading, Ozon2AliExportStep },
  data: () => ({
    isCheckingCount: false
  }),

  computed: {
    ...mapState('ozon2ali/task', ['fromShop', 'toShop', 'currentStep', 'totalSteps', 'taskData', 'countByType']),
    ...mapGetters('ozon2ali/task', [
      'metricsPayload',
      'payMetricsPayload',
      'taskLoadingStatus',
      'productListForExportIds'
    ]),

    hasItemsForExport () {
      return this.productListForExportIds?.length || this.countByType[PRODUCT_STATUS.FOR_EXPORT]
    },

    isGoToSecondStepDisabled () {
      return !(this.fromShop && this.toShop)
    },

    isExportStarted () {
      return this.taskLoadingStatus.exportStatus !== EXPORT_STATUS.DRAFT
    },

    isBackBtnDisabled () {
      return this.currentStep === 2 || this.isExportStarted
    }
  },

  beforeDestroy () {
    this.resetCurrentStep()
  },

  methods: {
    ...mapActions('ozon2ali/task', ['setCurrentStep', 'resetCurrentStep', 'getExportCountByType']),

    goToSecondStep () {
      this.setCurrentStep(2)
    },

    async checkCount (callback) {
      this.isCheckingCount = true
      await this.getExportCountByType()

      if (this.countByType[PRODUCT_STATUS.FOR_EXPORT]) {
        callback()
      } else {
        toastNotification.error('Выберите хотя-бы один товар')
      }
      this.isCheckingCount = false
    },

    goToThirdStep () {
      if (this.productListForExportIds.length) {
        this.setCurrentStep(3)
      } else {
        this.checkCount(this.setCurrentStep.bind(null, 3))
      }
    },

    goToFourthStep () {
      if (this.productListForExportIds.length) {
        this.openPayModal()
      } else {
        this.checkCount(this.openPayModal)
      }
    },

    openPayModal () {
      this.$dialog.show(Ozon2AliExportPayModal, () => ({
        onSuccess: () => this.setCurrentStep(4),
        onCancel: () => this.setCurrentStep(3)
      }))
    },

    goToTaskList () {
      this.resetCurrentStep()
      this.$router.push({ name: 'mp-to-mp' })
    },

    goToPrevStep () {
      this.setCurrentStep(this.currentStep - 1)
    },

    formatDate (date) {
      return dateFormat(date)
    },

    formatTime (date) {
      return timeFormat(date)
    }
  }
}
</script>

<style lang="stylus">
.o2a-export-header
  padding 24px
  display flex
  margin-top 24px
  min-height 185px

  &__left
    min-width: 70%
    max-width: 70%
    padding-right 27px
    border-right: 1px solid $colors.gray-light

    &-bottom
      display flex
      align-items center
      margin-top 12px

  &__item
    min-width: 30%
    max-width: 30%

    &:first-child
      min-width 40%

    &-label
      color: $colors.gray-darker

    &-value
      margin-top 4px

  &__heading
    margin-bottom 20px !important

  &__date
    margin-top 12px
    text-align center
    color: $colors.gray-darker

    &--date
      font-weight: 600
      color: $colors.gray-darkest-2

    &--time
      font-weight: 600

  &__right
    min-width: 30%
    max-width: 30%
    padding-left 27px

    .x-btn
      width: 100%

      & + .x-btn
        margin-left 0
        margin-top 8px

</style>
