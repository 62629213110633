<template>
  <ul class="filters">
    <div class="filters__top">
      <li
        class="filters__item filters__category"
      >
        <x-form-item
          class="field-composition_content_filter"
          name="Категория"
          label="Категория"
        >
          <x-select
            class="form-field-box_content_filter"
            track-by="id"
            :value="category"
            :options="categories"
            :limit="1"
            label="name"
            :allow-empty="false"
            placeholder="Все категории"
            @select="category => $emit('update:category', category)"
          />
        </x-form-item>
      </li>

      <li class="filters__item filters__createdAt">
        <x-form-item
          name="Дата создания"
          label="Дата создания"
        >
          <datepicker
            border
            has-reset-button
            :value="createdAtPeriod"
            @change="period => $emit('update:created-at-period', period)"
          />
        </x-form-item>
      </li>

      <li class="filters__item filters__search">
        <x-form-item
          name="Поиск"
          label="Поиск"
        >
          <search
            :value="search"
            @input="term => $emit('update:search', term || null)"
          />
        </x-form-item>
      </li>

      <li class="filters__item filters__item_content_reset">
        <x-btn
          outline
          prepend="x"
          @click="$emit('reset')"
        >
          Очистить всё
        </x-btn>
      </li>
    </div>
    <div class="filters__bottom">
      <li class="filters__item filters__item--filter-title">
        <slot name="filterTitle" />
      </li>
      <li class="filters__item filters__item--filter-controls">
        <slot name="controls" />
      </li>
      <template v-if="showGlobalControls">
        <li class="filters__item filters__item--button">
          <x-popover
            popover-class="filters-popover"
            trigger="click"
            placement="bottom"
          >
            <x-btn
              append="dropdown-down"
              prepend="burger"
            >
              Действия
            </x-btn>
            <template #popover>
              <div class="filters__item-popover">
                <div
                  v-if="showAddButton"
                  v-close-popover
                  class="filters__item-popover-item"
                  @click="selectAllPage"
                >
                  Отметить товары на этой странице
                </div>
                <div
                  v-close-popover
                  class="filters__item-popover-item"
                  @click="removeAllPage"
                >
                  Убрать товары на этой странице
                </div>
                <div
                  v-if="showAddButton"
                  v-close-popover
                  class="filters__item-popover-item"
                  @click="selectAll"
                >
                  Отметить все товары
                </div>
                <div
                  v-close-popover
                  class="filters__item-popover-item"
                  @click="removeAll"
                >
                  Убрать все товары
                </div>
              </div>
            </template>
          </x-popover>
        </li>
      </template>
    </div>
  </ul>
</template>

<script>
import Datepicker from '@/components/Interface/Datepicker.vue'
import Search from '@/components/Product/Filter/Search'
import { mapGetters, mapState } from 'vuex'

const DEFAULT_CATEGORY = {
  id: 'all',
  name: 'Все'
}

const emptyOption = {
  start: null,
  end: null
}

export default {
  name: 'Ozon2AliExportFilter',
  components: {
    Datepicker,
    Search
  },

  props: {
    category: {
      type: Object,
      default: () => DEFAULT_CATEGORY
    },
    search: {
      type: String,
      default: ''
    },
    createdAtPeriod: {
      type: Object,
      default: () => emptyOption
    },
    showAddButton: Boolean,
    showGlobalControls: Boolean
  },

  computed: {
    ...mapState('ozon2ali/task', ['currentStep']),
    ...mapGetters('ozon2ali/task', [
      'productCategories',
      'metricsPayload'
    ]),

    categories () {
      return this.productCategories ? [DEFAULT_CATEGORY, ...this.productCategories] : [DEFAULT_CATEGORY]
    },

    removePageItemsMetrics () {
      return this.currentStep === 3
        ? 'mp2mp_export_delete_page_check'
        : 'mp2mp_export_delete_page'
    },

    removeAllItemsMetrics () {
      return this.currentStep === 3
        ? 'mp2mp_export_delete_all_check'
        : 'mp2mp_export_delete_all'
    }
  },

  methods: {
    selectAllPage () {
      this.$metrics.hit('mp2mp_export_select_page', this.metricsPayload)
      this.$emit('select-all-page')
    },
    removeAllPage () {
      this.$metrics.hit(this.removePageItemsMetrics, this.metricsPayload)
      this.$emit('remove-all-page')
    },
    selectAll () {
      this.$metrics.hit('mp2mp_export_select_all', this.metricsPayload)
      this.$emit('select-all')
    },
    removeAll () {
      this.$metrics.hit(this.removeAllItemsMetrics, this.metricsPayload)
      this.$emit('remove-all')
    }
  }
}
</script>

<style lang="stylus">
.filters-popover
  margin-top 10px
</style>

<style lang="stylus" scoped>
.filters
  margin-left -10px
  padding 0
  list-style none

  &__top
    display flex
    flex-wrap wrap
    align-items center
    margin-bottom: 20px

  &__bottom
    display flex
    align-items center
    height: 36px

  &__item
    margin-left 10px
    margin-bottom 10px
    height: 100%
    display: flex
    align-items center

    &_content_search,
    &_content_reset
      padding-top 21px

    &--filter-title
      padding-right 17px
      border-right: 1px solid rgba(60, 60, 71, 0.1)

    &--button
      margin-left: auto
      max-width: 170px
      margin-right 10px

    &-popover
      width 241px
      box-shadow: 0px 6px 16px rgba(5, 2, 55, 0.12)
      border-radius: 4px

      &-item
        height 36px
        text-align: right
        padding 8px
        color: $colors.gray-darkest-2
        cursor pointer

        &:hover {
          background-color $colors.gray-lighter
        }

  &__category
  &__createdAt
    min-width 261px
    max-width 261px

  &__search
    min-width 391px
    max-width 391px

.checkbox
  >>> .checkbox__text
    color #2c2c37
</style>
