<template>
  <div class="o2a-export">
    <ozon2-ali-export-header />
    <component :is="currentComponentStep" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import Ozon2AliExportHeader from '@/components/Ozon2Ali/Export/Ozon2AliExportHeader'
import Ozon2AliExportSelectShops from '@/components/Ozon2Ali/Export/steps/Ozon2AliExportSelectShops'
import Ozon2AliExportSecondStep from '@/components/Ozon2Ali/Export/steps/Ozon2AliExportSecondStep'
import Ozon2AliExportThirdStep from '@/components/Ozon2Ali/Export/steps/Ozon2AliExportThirdStep'
import Ozon2AliExportFourthStep from '@/components/Ozon2Ali/Export/steps/Ozon2AliExportFourthStep'

export default {
  name: 'Ozon2AliExport',
  components: { Ozon2AliExportSelectShops, Ozon2AliExportHeader },

  computed: {
    ...mapState('ozon2ali/task', ['currentStep']),

    currentComponentStep () {
      switch (this.currentStep) {
        case 1:
          return Ozon2AliExportSelectShops
        case 2:
          return Ozon2AliExportSecondStep
        case 3:
          return Ozon2AliExportThirdStep
        case 4:
          return Ozon2AliExportFourthStep
        default:
          return 'div'
      }
    }
  },
  created () {
    const taskId = this.$route.params.taskId
    const step = this.$route.params.step || 2

    if (taskId === 'new') {
      this.setCurrentStep(1)
    }
    if (Number(taskId)) {
      this.setCurrentStep(step)
    }
  },

  beforeDestroy () {
    this.resetCurrentStep()
  },

  methods: {
    ...mapActions('ozon2ali/task', ['setCurrentStep', 'resetCurrentStep'])
  }

}
</script>

<style lang="stylus" scoped>
.preloader {
  height 40vh
  margin-top 24px
}
</style>
