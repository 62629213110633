var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('grid',{attrs:{"auto":"","actions":_vm.actions,"cells":_vm.productList,"columns":_vm.columns,"errors":_vm.productErrors},scopedSlots:_vm._u([{key:"id",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"grid-steps__id"},[_c('div',{staticClass:"external-title"},[_c('div',{staticClass:"external-title-id"},[_c('div',[_vm._v(_vm._s(_vm.getExternalId(row)))]),(row.origin_product_marketplace_url)?_c('div',[_c('x-link',{directives:[{name:"hint",rawName:"v-hint",value:({
                popperClass: 'grid-steps-tooltip',
                placement: 'auto',
                content: 'Ссылка на страницу товара на маркетплейсе',
                triggers: ['hover'],
                delay: 100
              }),expression:"{\n                popperClass: 'grid-steps-tooltip',\n                placement: 'auto',\n                content: 'Ссылка на страницу товара на маркетплейсе',\n                triggers: ['hover'],\n                delay: 100\n              }"}],attrs:{"primary":"","href":row.origin_product_marketplace_url}},[_c('x-icon',{staticClass:"modal-body__icon",attrs:{"name":"link","size":"s"}})],1)],1):_vm._e()]),(_vm.hasOffer(row))?_c('div',[(_vm.hasFieldError(row, 'vendor_code'))?_c('span',{staticClass:"grid-steps__vendor-code grid-steps__vendor-code--editable",on:{"click":function($event){return _vm.onPropEdit(row, 'vendor_code')}}},[_c('span',{staticClass:"grid-steps__vendor-code-prepend"},[_vm._v("Артикул: ")]),_c('span',{staticClass:"grid-steps__vendor-code-editable-text"},[_vm._v(_vm._s(row.product_data.offers[0].vendor_code)+" ")]),_c('x-icon',{staticClass:"grid-steps__vendor-code-icon",attrs:{"name":"edit","size":"s"}})],1):_c('span',{staticClass:"grid-steps__vendor-code grid-steps__vendor-code--not-editable"},[_vm._v(" Артикул: "+_vm._s(row.product_data.offers[0].vendor_code)+" ")])]):_vm._e(),(_vm.hasBarcode(row))?_c('span',[_vm._v("Штрихкод: "+_vm._s(row.product_data.offers[0].barcode))]):_vm._e()])])]}},{key:"name",fn:function(ref){
              var row = ref.row;
return [_c('div',[(_vm.hasFieldError(row, 'name'))?_c('span',{staticClass:"grid-steps__name grid-steps__name--editable",on:{"click":function($event){return _vm.onPropEdit(row, 'name')}}},[_c('span',{staticClass:"grid-steps__name-editable-text"},[_vm._v(_vm._s(_vm.getProductName(row)))]),_c('x-icon',{staticClass:"grid-steps__name-icon",attrs:{"name":"edit","size":"s"}})],1):_c('span',{staticClass:"grid-steps__name grid-steps__name--not-editable"},[_vm._v(" "+_vm._s(_vm.getProductName(row))+" ")])])]}},{key:"image",fn:function(ref){
              var row = ref.row;
return [_c('div',{staticClass:"grid-steps__image"},[(_vm.hasImages(row))?[_c('div',_vm._l((row.product_data.images.slice(0, 6)),function(photo,pId){return _c('div',{key:pId},[_c('img',{attrs:{"src":photo.url,"alt":""}})])}),0)]:_vm._e()],2)]}},{key:"category",fn:function(ref){
              var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getCategoryName(row))+" ")]}},{key:"errors",fn:function(ref){
              var row = ref.row;
return [(_vm.hasError(row))?[_c('div',{staticClass:"grid-steps__item-error"},[_c('div',_vm._l((row.validation_error),function(error,i){return _c('p',{key:i,staticClass:"grid-steps__item-error-text"},[(error.details)?_c('i-tooltip',{staticClass:"mr-1",attrs:{"content":error.details}}):_vm._e(),_vm._v(" "+_vm._s(error.text)+" ")],1)}),0)])]:[_c('div',{staticClass:"grid-steps__item-error"},[_c('div',[_c('p',{staticClass:"grid-steps__item-error-notext"},[_vm._v(" Нет ошибок ")])])])]]}},{key:"recommendations",fn:function(ref){
              var row = ref.row;
return [(_vm.hasError(row))?[_c('div',{staticClass:"grid-steps__item-error"},[_c('div',_vm._l((row.validation_error),function(error,i){return _c('p',{key:i,staticClass:"grid-steps__item-error-text"},[_vm._v(" "+_vm._s(error.recommendation)+" ")])}),0)])]:[_c('div',{staticClass:"grid-steps__item-error"},[_c('div',[_c('p',{staticClass:"grid-steps__item-error-notext"},[_vm._v(" Нет рекомендаций ")])])])]]}},{key:"url",fn:function(ref){
              var row = ref.row;
return [(row.prev_imported_product_marketplace_url)?[_c('x-link',{directives:[{name:"hint",rawName:"v-hint",value:({
          popperClass: 'grid-steps-tooltip',
          placement: 'auto',
          content: row.prev_imported_product_marketplace_url,
          triggers: ['hover'],
          delay: 100
        }),expression:"{\n          popperClass: 'grid-steps-tooltip',\n          placement: 'auto',\n          content: row.prev_imported_product_marketplace_url,\n          triggers: ['hover'],\n          delay: 100\n        }"}],attrs:{"primary":"","href":row.prev_imported_product_marketplace_url}},[_vm._v(" Ссылка на сайт ")])]:[_c('div',{staticClass:"grid-steps__item-error"},[_c('div',[_c('p',{staticClass:"grid-steps__item-error-notext"},[_vm._v(" Товар ранее не добавлялся ")])])])]]}},{key:"actions",fn:function(ref){
        var row = ref.row;
return [(_vm.removeOnly)?[_c('x-btn',{attrs:{"outline":"","prepend":"delete-2","metrics":"mp2mp_export_delete","metrics-payload":_vm.metricsPayload},on:{"click":function($event){return _vm.$emit('remove-row', row)}}},[_vm._v(" Удалить ")])]:[(_vm.isRowSelected(row))?_c('x-btn',{attrs:{"disabled":_vm.isRowDisabled(row),"prepend":"check","metrics":"mp2mp_export_delete","metrics-payload":_vm.metricsPayload},on:{"click":function($event){return _vm.$emit('remove-row', row)}}},[_vm._v(" Добавлено ")]):_c('x-btn',{attrs:{"disabled":_vm.isRowDisabled(row),"prepend":"plus","outline":"","metrics":"mp2mp_export_add","metrics-payload":_vm.metricsPayload},on:{"click":function($event){return _vm.$emit('select-row', row)}}},[_vm._v(" Добавить ")])]]}},{key:"createdAt",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatDate(row.created_at))+" "),_c('br'),_c('span',{staticClass:"grid-steps__time"},[_vm._v(_vm._s(_vm.formatTime(row.created_at)))])]}},{key:"resultUrl",fn:function(ref){
        var row = ref.row;
return [(row.result_product_marketplace_url)?[_c('x-link',{directives:[{name:"hint",rawName:"v-hint",value:({
          popperClass: 'grid-steps-tooltip',
          placement: 'auto',
          content: row.result_product_marketplace_url,
          triggers: ['hover'],
          delay: 100
        }),expression:"{\n          popperClass: 'grid-steps-tooltip',\n          placement: 'auto',\n          content: row.result_product_marketplace_url,\n          triggers: ['hover'],\n          delay: 100\n        }"}],attrs:{"primary":"","href":row.result_product_marketplace_url}},[_vm._v(" Ссылка на сайт ")])]:[_c('div',{staticClass:"grid-steps__item-error"},[_c('div',[_c('p',{staticClass:"grid-steps__item-error-notext"},[_vm._v(" — ")])])])]]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }