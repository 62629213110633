<template>
  <div class="search">
    <x-input
      class="search__input"
      type="text"
      prepend-icon="search"
      :placeholder="placeholder"
      :value="value"
      @input="onInput"
    />
  </div>
</template>

<script>
import _debounce from 'lodash.debounce'

let debouncedInput

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Что ищем?'
    },
    debounced: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      selfValue: ''
    }
  },

  created () {
    debouncedInput = _debounce(() => this.$emit('input', this.selfValue), 300)
  },

  methods: {
    onInput (term) {
      if (this.debounced) {
        this.selfValue = term
        return debouncedInput()
      }
      this.$emit('input', term)
    }
  }
}
</script>

<style lang="stylus" scoped>
  .search
    display flex

    &__input
      flex-grow 1
</style>
