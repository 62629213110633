import moment from 'moment'

export const timeFormat = (date, format = 'HH:mm:ss', options = {}) => {
  if (!date) return '-'
  const {
    utc = 3,
    locale = 'ru'
  } = options

  return moment(new Date(date)).utcOffset(utc).locale(locale).format(format)
}
