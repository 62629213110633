<template>
  <div class="o2a-export-step">
    <template v-for="step in stepCount">
      <div
        :key="step"
        class="o2a-export-step__step"
        :class="getStepClasses(step)"
      >
        <template v-if="isStepBeforeCurrent(step)">
          <x-icon
            name="check"
            size="s"
          />
        </template>
        <template v-else-if="isFinalStep(step)">
          <x-icon
            name="flag"
            size="s"
          />
        </template>
        <template v-else>
          {{ step }}
        </template>
      </div>
      <div
        v-if="!isFinalStep(step)"
        :key="`${step}__line`"
        class="o2a-export-step__line"
      />
    </template>
  </div>
</template>

<script>
export default {
  name: 'Ozon2AliExportStep',
  props: {
    currentStep: {
      type: Number,
      default: 1
    },
    stepCount: {
      type: Number,
      required: true
    }
  },
  methods: {
    getStepClasses (step) {
      return {
        'o2a-export-step__step--current': this.isCurrentStep(step),
        'o2a-export-step__step--before-current': this.isStepBeforeCurrent(step)
      }
    },

    isCurrentStep (step) {
      return step === this.currentStep
    },

    isStepBeforeCurrent (step) {
      return step < this.currentStep
    },

    isFinalStep (step) {
      return step === this.stepCount
    }
  }
}
</script>

<style lang="stylus">
.o2a-export-step
  display flex
  width 100%
  justify-content space-between
  position relative

  &__step
    min-width 36px
    max-width 36px
    height 36px
    border-radius 50%
    display flex
    justify-content center
    align-items center
    border: 2px solid $colors.gray-dark
    color: $colors.gray-dark

    &--current
    &--before-current
      border-color: $colors.primary

    &--current
      background-color: $colors.primary
      color: $colors.white

    &--before-current
      color: $colors.primary

  &__line
    border-top: 1px dashed $colors.gray-dark
    width: calc(100% / 3)
    position relative
    top 18px

.o2a-export-step__step--before-current + .o2a-export-step__line
  border-top: 1px solid $colors.primary
</style>
