/**
 * @param importStatus: IMPORT_STATUS
 * @param exportStatus: EXPORT_STATUS
 * */
export default (importStatus, exportStatus) => {
  const importMap = {
    inprogress: {
      text: 'Импортируем...',
      color: 'orange'
    },
    failure: {
      text: 'Ошибка импорта',
      color: 'red'
    }
  }

  const exportMap = {
    draft: {
      text: 'Ожидает выбора',
      color: 'orange'
    },
    inprogress: {
      text: 'Экспортируем...',
      color: 'blue'
    },
    done: {
      text: 'Завершена',
      color: 'gray'
    }
  }

  if (importMap[importStatus]) {
    return importMap[importStatus]
  }

  if (exportMap[exportStatus]) {
    return exportMap[exportStatus]
  }

  return {
    text: 'Ошибка',
    color: 'red'
  }
}
