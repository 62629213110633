<template>
  <x-card class="o2a-export-third">
    <heading level="3">
      Список товаров
    </heading>
    <alert-bar>
      Проверьте список товаров, если все хорошо, нажмите «Оплатить и Экспортировать». Если хотите дополнить список, вернитесь на шаг назад, нажав кнопку «Назад»
    </alert-bar>
    <ozon2-ali-export-filter
      class="o2a-export-third__filter"
      :category.sync="filters.category"
      :created-at-period.sync="filters.createdAtPeriod"
      :search.sync="filters.search"
      show-global-controls
      @reset="resetFilters"
      @remove-all="removeAll"
      @remove-all-page="removeAllPage"
    />
    <template v-if="isProductListLoading">
      <x-loader />
    </template>
    <template v-else>
      <grid-steps
        class="o2a-export-third__grid"
        :product-list="productList"
        :columns="columns"
        remove-only
        @remove-row="removeProduct"
      />
      <x-paginator
        :options="pagination"
        @input="setPage"
      />
    </template>
  </x-card>
</template>

<script>
import STATUS from '@/constants/status_api'

import Heading from '@/components/Common/Heading'
import GridSteps from '@/components/Ozon2Ali/Export/GridSteps'
import Ozon2AliExportFilter from '@/components/Ozon2Ali/Export/steps/Ozon2AliExportFilter'
import AlertBar from '@/components/Interface/AlertBar'
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: 'Ozon2AliExportThirdStep',
  components: { GridSteps, Heading, Ozon2AliExportFilter, AlertBar },
  data () {
    return {
      filters: {
        category: undefined,
        createdAtPeriod: {
          start: '',
          end: ''
        },
        search: ''
      },

      columns: [
        {
          name: 'ID на маркетплейсе',
          type: 'id',
          freezed: true,
          styles: {
            width: '190px'
          }
        },
        {
          name: 'Наименование',
          type: 'name',
          styles: {
            width: '190px'
          }
        },
        {
          name: 'Фото',
          type: 'image'
        },
        {
          name: 'Дата создания',
          type: 'createdAt'
        },
        {
          name: 'Категория',
          type: 'category'
        }
      ],

      stepParams: { statuses: 'for_export' },

      pagination: {
        limit: 10,
        page: 1,
        offset: 0,
        total: 0
      }
    }
  },
  watch: {
    filters: {
      handler () {
        this.resetPagination()
        this.fetchProductList()
      },
      deep: true
    }
  },
  computed: {
    ...mapState('ozon2ali/task', ['taskData', 'productList', 'productListStatus']),
    ...mapGetters('ozon2ali/task', ['productListForExportIds']),

    isProductListLoading () {
      return this.productListStatus === STATUS.LOADING
    },

    paginationParams () {
      return {
        limit: this.pagination.limit,
        offset: this.pagination.offset
      }
    }
  },

  created () {
    this.fetchProductList()
  },

  methods: {
    ...mapActions('ozon2ali/task',
      [
        'removeExportItems',
        'setItemsToExport',
        'fetchProductListAction',
        'removeAllImportedProductsByFilter',
        'setCurrentStep'
      ]),

    resetPagination () {
      this.pagination.page = 1
      this.pagination.offset = 0
    },

    setPage (page) {
      this.pagination = page
      this.fetchProductList()
    },

    async removeAll () {
      await this.removeAllImportedProductsByFilter({ filter: this.getFilterParams(), removeLocalItem: true })

      this.toPrevStep()
    },

    async removeAllPage () {
      await this.removeExportItems({ productIds: this.productListForExportIds, removeLocalItem: true })

      this.toPrevStep()
    },

    async removeProduct (product) {
      await this.removeExportItems({ productIds: [product.id], removeLocalItem: true })

      this.toPrevStep()
    },

    async fetchProductList () {
      this.pagination.total = await this.fetchProductListAction({ ...this.paginationParams, ...this.getFilterParams(), ...this.stepParams })
    },

    getFilterParams () {
      const obj = {}

      if (this.filters.createdAtPeriod.start) {
        obj.created_at_from = this.filters.createdAtPeriod.start
      }

      if (this.filters.createdAtPeriod.end) {
        obj.created_at_to = this.filters.createdAtPeriod.end
      }

      if (this.filters.search) {
        obj.search = this.filters.search
      }

      if (this.filters.category && this.filters.category.id !== 'all') {
        obj.product_category_id = this.filters.category.id
      }

      return obj
    },

    resetFilters () {
      this.filters = {
        category: undefined,
        createdAtPeriod: {
          start: '',
          end: ''
        },
        search: ''
      }
    },
    async toPrevStep () {
      if (!this.productList.length) {
        this.resetPagination()
        await this.fetchProductList()

        if (!this.pagination.total) this.setCurrentStep(2)
      }
    }
  }
}
</script>

<style lang="stylus">
.o2a-export-third
  margin-top 24px
  padding 24px
  min-height 318px

  &__grid
    margin-bottom 20px

    .grid__cell_content_actions .grid__cell-in
      justify-content center

  .x-paginator
    margin-top: auto

  &__filter
    margin-top 20px
    margin-bottom 22px
</style>
