<template>
  <span
    class="task-status"
    :class="getTaskClasses"
  >
    {{ getTaskText }}
  </span>
</template>

<script>
import getTaskStatus from '@/business-logic/mp-2-mp/getTaskStatus'

export default {
  name: 'TaskStatus',

  props: {
    task: {
      type: Object,
      required: true
    }
  },

  computed: {
    getTaskText () {
      return getTaskStatus(this.task.linked_import_list?.status, this.task.status).text
    },

    getTaskClasses () {
      return {
        [`task-status--${getTaskStatus(this.task.linked_import_list?.status, this.task.status).color}`]: true
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.task-status
  font-size 14px
  line-height 16px
  border-radius 10px
  padding 2px 8px
  white-space nowrap

  &--gray
    background #F7F7F8
    color #3C3C47

  &--orange
    background #FFF4EC
    color #EE5424

  &--red
    background #FFEBEE
    color #D91D4B

  &--blue
    color #118cff
    background #e5f0ff

</style>
